import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/auth.models";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class EcrfAuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public baseUrl: string;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseUrl = environment.apiUrl;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    let loginUrl = "/user/login";
    return this.http.post<any>(loginUrl, { email, password }).pipe(
      map((response) => {
        // login successful if there's a jwt token in the response
        let loginUser = response?.payLoad;
        console.log("🚀 ~ file: ecrf-auth.service.ts ~ line 32 ~ EcrfAuthService ~ map ~ loginUser", loginUser)
        if (loginUser && loginUser?.authToken) {
          console.log("🚀 ~ file: ecrf-auth.service.ts ~ line 34 ~ EcrfAuthService ~ map ~ loginUser?.authToken", loginUser?.authToken)
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(loginUser));
          this.currentUserSubject.next(loginUser);
        }
        return loginUser;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
}
