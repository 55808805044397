import { MenuItem } from "./menu.model";

export const LAB_MENU: MenuItem[] = [
  // {
  //   id: 1,
  //   label: "MENUITEMS.DASHBOARDS.TEXT",
  //   icon: "bx-home-circle",
  //   // subItems: [
  //   //   {
  //   //     id: 1,
  //   //     label: "Menu",
  //   //     link: '/lab/dashboard',
  //   //     parentId: 1
  //   //   }
  //   // ]
  // },
  {
    id: 2,
    label: "Patient",
    icon: "bx-list-ol",
    subItems: [
      {
        id: 1,
        label: "List",
        link: '/lab/patients',
        parentId: 2
      },
      {
        id: 2,
        label: "Add Patient",
        link: '/lab/add',
        parentId: 2
      }
    ]
  }
];
