<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © 4basecare
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Designed & Developed by 4baseCare
                </div>
            </div>
        </div>
        <div class="row" *ngIf="appUser?.countryRegion=='DB' && (appUser?.role=='LAB_ADMIN' || appUser?.role=='LAB_USER')">
          <div class="col-sm-6">
            #212, Innovate Life Sciences FZ-LLC Second Floor, Laboratory Complex Dubai Science Park, Dubai, UAE
          </div>
        </div>
    </div>
</footer>
