import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/auth.models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();
  appUser: User;

  constructor() { }

  ngOnInit() {
    this.appUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("appUser->",this.appUser)
  }

}
