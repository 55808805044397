import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { RoleService } from "../services/role.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(public roleservice: RoleService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.roleservice.returnRole() == "ADMIN" || this.roleservice.returnRole() == "USER" || this.roleservice.returnRole() == "LAB_ADMIN" || this.roleservice.returnRole() == "DOCTOR_USER" || this.roleservice.returnRole() == "ACCESS_USER" ||this.roleservice.returnRole() == "BIO_INFORMATICIAN"||this.roleservice.returnRole() == "QC_USER") {
      return true;
    } else {
      localStorage.clear();
      this.router.navigate(["/account/login"]);
    }

  }
}
