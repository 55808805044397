import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { AuthenticationService } from "../services/auth.service";
import { AuthfakeauthenticationService } from "../services/authfake.service";

import { environment } from "../../../environments/environment";
import { EcrfAuthService } from "../services/ecrf-auth.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  baseUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    private ecrfAuthService: EcrfAuthService,
    private authfackservice: AuthfakeauthenticationService
  ) {
    this.baseUrl = environment.apiUrl;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = this.ecrfAuthService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.authToken;
    let requestURL = request.toString();
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.authToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
